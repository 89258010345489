
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import RSCDBackend from '../../../lib/i18n/rs-cd-backend';
import { browserDetector } from '../../../lib/i18n/vanity-locale';
import tt2TagsPostProcessor from '../../../lib/i18n/tt2-tags-post-processor';
import directive from './directive';

import testResources from './test-resources';

const
    initOpts = {"preload":["en"],"debug":false,"defaultNS":"global","fallbackLng":["en"],"returnNull":true,"returnObjects":true,"ns":["global","links","buttons","share"],"detection":{"lookupQuerystring":"locale","order":["querystring","vanity","navigator","header"],"caches":false},"postProcess":["tt2Tags"]},
    { enable: enableVanityLocale } = {"_public":true,"enable":true,"defaultLocale":"en-CA","pairs":[{"pattern":"(fr|joursetoilesstarbucks\\.ca|-frca)","locale":"fr-CA"},{"pattern":"(\\\\.ca|-ca)","locale":"en-CA"}]};

const languageDetector = new LanguageDetector();
if (enableVanityLocale) {
    languageDetector.addDetector(browserDetector);
}

export const initPromise = i18next
.use(RSCDBackend)
.use(languageDetector)
.use(tt2TagsPostProcessor)
.init({
    ...initOpts,

    resources: testResources,
    // Load additional resource bundles from backend.
    partialBundledLanguages: true,
    // Use empty string for missing keys on the front-end.
    parseMissingKeyHandler: () => '',
    // parseMissingKeyHandler: (key) => `[Missing: ${key}]`,
    // debug: true,
});

/**
 * Do a better job sorting copydeck nodes than JS does by itself. Also filters
 * out all "special" copydeck properties that start with "."
 *
 * TODO: support copydeck's special `.order` property?
 * @param {Object<string, string>} copy
 * @returns {Array<string>} sorted node values
 */
export function sortCopyNodes (copy) {
    return Object.keys(copy)
    .filter(key => !key.startsWith('.'))
    // AlphaNumeric sort.
    .sort((a, b) => a.localeCompare(b, 'en', { numeric: true }))
    .map(key => copy[key]);
}

export { i18next, directive as i18nextDirective };
