
import { computed, nextTick, ref, toValue } from 'vue';
import { useStore } from 'vuex';

import { track } from '../plugins/WebAnalytics';


const focusableSelector = [
    'a[href]',
    'area[href]',
    'input:not([disabled])',
    'select:not([disabled])',
    'textarea:not([disabled])',
    'button:not([disabled])',
    'iframe',
    '[tabindex]',
    '[contentEditable=true]',
].join(',');


export function useBaseModal (modalName, modalRef, escapeKeyClosesModal = true) {
    const store = useStore();
    const isOpen = ref(false);

    let promiseResolve, promiseReject;


    // Computed
    const focusableElements = computed(() => {
        const modalEl = toValue(modalRef);
        return modalEl?.querySelectorAll(focusableSelector);
    });


    // Methods
    function cancelModal (arg) {
        handleClose();
        promiseReject(arg);
    }

    function closeModal (arg) {
        handleClose();
        promiseResolve(arg);
    }

    function handleClose () {
        document.body.removeAttribute('data-e2e-modal');
        store.commit('ui/modalClose');
        track('Close', { category: 'Modal', label: modalName });

        toValue(modalRef).removeEventListener('keydown', keydownListener);

        isOpen.value = false;
    }

    function handleOpen () {
        document.body.setAttribute('data-e2e-modal', modalName);
        store.commit('ui/modalOpen', { modalName });
        track('Open', { category: 'Modal', label: modalName });

        toValue(modalRef).addEventListener('keydown', keydownListener);

        isOpen.value = true;

        nextTick(() => {
            focusableElements.value[0]?.focus();
        });
    }

    function keydownListener (event) {
        const firstFocusableElement = focusableElements.value.item(0),
            lastFocusableElement = focusableElements.value.item(focusableElements.value.length - 1);

        switch (event.code) {
            case ('Tab'):
                if (event.shiftKey && document.activeElement === firstFocusableElement) {
                    event.preventDefault();
                    lastFocusableElement?.focus();
                }
                else if (document.activeElement === lastFocusableElement) {
                    event.preventDefault();
                    firstFocusableElement?.focus();
                }
                break;
            case ('Escape'):
                if (escapeKeyClosesModal) {
                    closeModal();
                }
                break;
            default:
                break;
        }
    }

    function openModal () {
        handleOpen();

        return new Promise((resolve, reject) => {
            promiseResolve = resolve;
            promiseReject = reject;
        });
    }

    return {
        cancelModal,
        closeModal,
        isOpen,
        modalName,
        openModal,
    };
}
