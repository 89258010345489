import { unref as _unref, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, vShow as _vShow, withModifiers as _withModifiers, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "ending-soon modal",
  role: "dialog",
  "aria-labelledby": "dialog1Title",
  "aria-describedby": "dialog1Desc"
}
const _hoisted_2 = { class: "modal-top" }
const _hoisted_3 = ["aria-label"]
const _hoisted_4 = { class: "visually-hidden" }
const _hoisted_5 = { class: "modal-content mt-s" }
const _hoisted_6 = { class: "body-copy mb-l mt-l" }

import { ref } from 'vue';
    import { useI18next } from '@composables/i18next';
    import { useBaseModal } from '@composables/modal';

    // Define component logic
    
export default {
  __name: 'EndingSoonModal',
  setup(__props, { expose: __expose }) {

    const rootEl = ref();

    const { closeModal, openModal, isOpen } = useBaseModal('ending-soon', rootEl);

    const { vT, t } = useI18next(['end_soon_modal']);


    // Expose methods
    __expose({
        openModal,
    });

return (_ctx, _cache) => {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _withDirectives(_createElementVNode("section", {
      ref_key: "rootEl",
      ref: rootEl,
      class: "modal-overlay",
      onClick: _cache[1] || (_cache[1] = _withModifiers((...args) => (_unref(closeModal) && _unref(closeModal)(...args)), ["self"]))
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("button", {
            class: "close",
            "aria-label": _unref(t)('aria:close_modal'),
            onClick: _cache[0] || (_cache[0] = (...args) => (_unref(closeModal) && _unref(closeModal)(...args)))
          }, [
            _cache[2] || (_cache[2] = _createTextVNode(" X ")),
            _withDirectives(_createElementVNode("span", _hoisted_4, null, 512), [
              [_unref(vT), 'aria:close_modal']
            ])
          ], 8, _hoisted_3)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _withDirectives(_createElementVNode("div", _hoisted_6, null, 512), [
            [_unref(vT), 'body_copy']
          ])
        ])
      ])
    ], 512), [
      [_vShow, _unref(isOpen)]
    ])
  ]))
}
}

}