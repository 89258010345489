import { unref as _unref, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, withModifiers as _withModifiers, Teleport as _Teleport, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "challenge-detail modal",
  role: "dialog",
  "aria-labelledby": "dialog1Title",
  "aria-describedby": "dialog1Desc"
}
const _hoisted_2 = { class: "modal-top" }
const _hoisted_3 = ["aria-label"]
const _hoisted_4 = { class: "visually-hidden" }
const _hoisted_5 = { class: "modal-content mb-l mt-l" }
const _hoisted_6 = ["aria-label"]
const _hoisted_7 = ["aria-label"]
const _hoisted_8 = {
  key: 0,
  class: "header-copy mb-m mt-m"
}
const _hoisted_9 = {
  key: 1,
  class: "header-copy mb-m mt-m"
}
const _hoisted_10 = {
  key: 2,
  class: "header-copy mb-m mt-m"
}
const _hoisted_11 = ["src", "alt"]
const _hoisted_12 = {
  key: 0,
  class: "header-copy mb-m mt-m"
}
const _hoisted_13 = {
  key: 1,
  class: "header-copy mb-m mt-m"
}
const _hoisted_14 = {
  key: 4,
  href: "https://starbucks.app.link/dmqJwB6FDxb",
  target: "_blank",
  class: "mt-s button has-background-moderate-gold"
}
const _hoisted_15 = {
  key: 0,
  class: "disclaimer"
}

import { ref, computed, nextTick } from 'vue';
    import { useI18next } from '@composables/i18next';
    import { useBaseModal } from '@composables/modal';
    import { useStore } from 'vuex';

    // Define props
    
export default {
  __name: 'ChallengeDetailModal',
  props: {
        challenge: {
            type: Object,
            required: true,
        },
    },
  setup(__props, { expose: __expose }) {

    const props = __props;

    // Define component logic
    const buttonClose = ref();
    const rootEl = ref();
    const store = useStore();

    const { closeModal, openModal, isOpen } = useBaseModal('challenge-detail', rootEl);

    const { vT, t } = useI18next(['challenges', 'aria', 'buttons']);

    // Computed properties
    const challengeId = computed(() => props.challenge.achievementName);

    const isCompleted = computed(() => props.challenge.results.completed);

    const inProgress = computed(() => !isCompleted.value && props.challenge.results.progress > 0);

    const progress = computed(() => props.challenge.results.progress);

    const target = computed(() => Number(props.challenge.properties.target));

    const isClaimable = computed(() => !isCompleted.value && progress.value === target.value);

    // Image handling
    const completedImg = require('@public/img/completed-checkmark.png');
    const incompleteImg = require('@public/img/started-checkmark.png');

    function image (task) {
        return task > progress.value > 0 && !isCompleted.value && !isClaimable.value ? incompleteImg : completedImg;
    }

    function altText (task) {
        return task > progress.value && !isCompleted.value ? t('challenges.not_started') : t('challenges.completed');
    }

    // Methods
    async function handleClaimButtonClick () {
        await store.dispatch('challenges/claimChallenge', { challengeId: challengeId.value });
        await store.dispatch('profile/getProfile');

        nextTick(() => { buttonClose.value.focus(); });
    }

    // Expose methods
    __expose({
        openModal,
    });

return (_ctx, _cache) => {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _withDirectives(_createElementVNode("section", {
      ref_key: "rootEl",
      ref: rootEl,
      class: "modal-overlay",
      onClick: _cache[1] || (_cache[1] = _withModifiers((...args) => (_unref(closeModal) && _unref(closeModal)(...args)), ["self"]))
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("button", {
            ref_key: "buttonClose",
            ref: buttonClose,
            class: "close",
            "aria-label": _unref(t)('aria:close_modal'),
            onClick: _cache[0] || (_cache[0] = (...args) => (_unref(closeModal) && _unref(closeModal)(...args)))
          }, [
            _cache[2] || (_cache[2] = _createTextVNode(" X ")),
            _withDirectives(_createElementVNode("span", _hoisted_4, null, 512), [
              [_unref(vT), 'aria:close_modal']
            ])
          ], 8, _hoisted_3)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", {
            class: _normalizeClass(["bingo-space", { completed: isCompleted.value, 'in-progress': inProgress.value }])
          }, [
            _withDirectives(_createElementVNode("div", {
              "aria-label": _unref(t)(`challenges.${challengeId.value}.name`)
            }, null, 8, _hoisted_6), [
              [_unref(vT), `challenges.${challengeId.value}.stylizedName`]
            ])
          ], 2),
          _withDirectives(_createElementVNode("div", {
            "aria-label": _unref(t)(`challenges.${challengeId.value}.body_copy_modal`),
            class: "body-copy mb-l mt-l"
          }, null, 8, _hoisted_7), [
            [_unref(vT), `challenges.${challengeId.value}.body_copy_modal`]
          ]),
          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "border" }, null, -1)),
          (isCompleted.value)
            ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_8, null, 512)), [
                [_unref(vT), 'challenges.completed']
              ])
            : (progress.value > 0)
              ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_9, null, 512)), [
                  [_unref(vT), 'challenges.in_progress']
                ])
              : _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_10, null, 512)), [
                  [_unref(vT), 'challenges.not_started']
                ]),
          _createElementVNode("ul", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(target.value, (task) => {
              return (_openBlock(), _createElementBlock("li", {
                key: task,
                class: "task-item"
              }, [
                _createElementVNode("img", {
                  src: image(task),
                  alt: altText(task),
                  class: "task-checkbox"
                }, null, 8, _hoisted_11)
              ]))
            }), 128))
          ]),
          _cache[4] || (_cache[4] = _createElementVNode("div", { class: "border" }, null, -1)),
          _createElementVNode("div", {
            class: _normalizeClass({ 'completed-image': isCompleted.value })
          }, [
            (isCompleted.value)
              ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_12, null, 512)), [
                  [_unref(vT), `challenges.${challengeId.value}.collected`]
                ])
              : _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_13, null, 512)), [
                  [_unref(vT), `challenges.${challengeId.value}.to_collect`]
                ])
          ], 2),
          (isClaimable.value)
            ? _withDirectives((_openBlock(), _createElementBlock("button", {
                key: 3,
                class: "mt-s button has-background-moderate-gold",
                onClick: handleClaimButtonClick
              }, null, 512)), [
                [_unref(vT), 'challenges.cta']
              ])
            : _withDirectives((_openBlock(), _createElementBlock("a", _hoisted_14, null, 512)), [
                [_unref(vT), 'links:order']
              ])
        ]),
        (!isClaimable.value && !isCompleted.value)
          ? _withDirectives((_openBlock(), _createElementBlock("p", _hoisted_15, null, 512)), [
              [_unref(vT), 'links:oamoe_copy']
            ])
          : _createCommentVNode("", true)
      ])
    ], 512), [
      [_vShow, _unref(isOpen)]
    ])
  ]))
}
}

}