import { createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "the-footer has-background-white" }
const _hoisted_2 = { class: "footer-container" }
const _hoisted_3 = { class: "footer-links" }
const _hoisted_4 = { class: "seperator" }
const _hoisted_5 = { class: "seperator" }
const _hoisted_6 = { class: "seperator" }
const _hoisted_7 = ["href"]
const _hoisted_8 = ["href"]
const _hoisted_9 = ["aria-label"]
const _hoisted_10 = {
  key: 0,
  class: "pb-xs"
}
const _hoisted_11 = { class: "disclaimer body legal pb-l" }

import { computed } from 'vue';
    import { useStore } from 'vuex';

    import { useI18next } from '@composables/i18next';
    import LanguageSelector from '@components/LanguageSelector.vue';
    import AeroplanContainer from '@components/AeroplanContainer.vue';

    import { openPopup } from '../popup';

    
export default {
  __name: 'TheFooter',
  setup(__props) {

    const store = useStore();

    const { t, vT } = useI18next(['footer', 'links', 'global']);

    const profile = computed(() => store.state.profile);
    const app = computed(() => store.state.app);
    const ui = computed(() => store.state.ui);
    const loggedIn = computed(() => store.getters['profile/loggedIn']);

    const faqUrl = computed(() => {
        if (store.state.app?.siteURL.includes('localhost')) {
            return `/faq/?locale=${store.state.app.locale}`;
        }

        return '/faq';
    });

    const logOut = () => store.dispatch('profile/logOut');

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createVNode(LanguageSelector),
    _createVNode(AeroplanContainer),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("nav", _hoisted_3, [
        _createElementVNode("ul", null, [
          _withDirectives(_createElementVNode("li", _hoisted_4, null, 512), [
            [_unref(vT), { key: 'links:rules', tb2Url: app.value.tb2URL }]
          ]),
          _withDirectives(_createElementVNode("li", _hoisted_5, null, 512), [
            [_unref(vT), 'links:privacy']
          ]),
          _createElementVNode("li", _hoisted_6, [
            _withDirectives(_createElementVNode("a", {
              href: _unref(t)('links:terms_of_use_url'),
              target: "_blank"
            }, null, 8, _hoisted_7), [
              [_unref(vT), 'links:terms_of_use_copy']
            ])
          ]),
          _createElementVNode("li", null, [
            _createElementVNode("a", {
              href: faqUrl.value,
              onClick: _cache[0] || (_cache[0] = _withModifiers((...args) => (_unref(openPopup) && _unref(openPopup)(...args)), ["prevent"]))
            }, _toDisplayString(_unref(t)('links:faq_copy')), 9, _hoisted_8)
          ]),
          _cache[2] || (_cache[2] = _createElementVNode("li", { class: "break" }, null, -1)),
          (loggedIn.value)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createElementVNode("li", null, [
                  _withDirectives(_createElementVNode("a", {
                    href: "/",
                    "data-e2e": "footer-logout",
                    "aria-label": _unref(t)('links:log_out'),
                    onClick: logOut
                  }, null, 8, _hoisted_9), [
                    [_unref(vT), 'links:log_out']
                  ])
                ]),
                _cache[1] || (_cache[1] = _createElementVNode("li", { class: "break" }, null, -1)),
                (_unref(store).state.app.phase !== 'survey')
                  ? (_openBlock(), _createElementBlock("li", _hoisted_10, [
                      _withDirectives(_createElementVNode("span", null, null, 512), [
                        [_unref(vT), 'links:oamoe_copy']
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true)
        ])
      ]),
      _withDirectives(_createElementVNode("p", _hoisted_11, null, 512), [
        [_unref(vT), { key: 'footer:disclaimer', tb2Url: app.value.tb2URL }]
      ])
    ])
  ]))
}
}

}