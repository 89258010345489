import { unref as _unref, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, vShow as _vShow, withModifiers as _withModifiers, Teleport as _Teleport, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "how-to-play modal",
  role: "dialog",
  "aria-labelledby": "dialog1Title",
  "aria-describedby": "dialog1Desc"
}
const _hoisted_2 = { class: "modal-top" }
const _hoisted_3 = ["aria-label"]
const _hoisted_4 = { class: "visually-hidden" }
const _hoisted_5 = { class: "modal-content mt-s" }
const _hoisted_6 = { class: "mb-s is-uppercase" }
const _hoisted_7 = {
  key: 0,
  class: "list-items"
}
const _hoisted_8 = {
  key: 0,
  class: "ellipse-container"
}
const _hoisted_9 = { class: "ellipse" }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = ["src"]
const _hoisted_12 = { class: "content" }
const _hoisted_13 = { class: "mt-xxs is-uppercase" }
const _hoisted_14 = { class: "paragraph has-text-centered mt-xxs pr-l pl-l" }
const _hoisted_15 = { key: 0 }

import { ref, computed } from 'vue';
    import { useI18next } from '@composables/i18next';
    import { useBaseModal } from '@composables/modal';

    // Define component logic
    
export default {
  __name: 'HowToPlayModal',
  setup(__props, { expose: __expose }) {

    const rootEl = ref();

    const { closeModal, openModal, isOpen } = useBaseModal('how-to-play', rootEl);

    const { vT, t } = useI18next(['onboarding']);


    // Expose methods
    __expose({
        openModal,
    });

    const onboardingSlides = computed(() => {
        const slides =
            Object.values(t('onboarding:slides')).filter(
                slide => slide?.headline,
            );

        if (slides.length === 0) return false;
        return slides;
    });

return (_ctx, _cache) => {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _withDirectives(_createElementVNode("section", {
      ref_key: "rootEl",
      ref: rootEl,
      class: "modal-overlay",
      onClick: _cache[1] || (_cache[1] = _withModifiers((...args) => (_unref(closeModal) && _unref(closeModal)(...args)), ["self"]))
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("button", {
            class: "close",
            "aria-label": _unref(t)('aria:close_modal'),
            onClick: _cache[0] || (_cache[0] = (...args) => (_unref(closeModal) && _unref(closeModal)(...args)))
          }, [
            _cache[2] || (_cache[2] = _createTextVNode(" X ")),
            _withDirectives(_createElementVNode("span", _hoisted_4, null, 512), [
              [_unref(vT), 'aria:close_modal']
            ])
          ], 8, _hoisted_3)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _withDirectives(_createElementVNode("h3", _hoisted_6, null, 512), [
            [_unref(vT), 'global:how_to_play']
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(onboardingSlides.value, (slide, index) => {
            return (_openBlock(), _createElementBlock("div", { key: index }, [
              (slide.img)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    (slide.img == 'ellipse-onboarding.png')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                          _createElementVNode("div", _hoisted_9, [
                            _createElementVNode("p", {
                              class: "is-uppercase",
                              innerHTML: slide.imgtext
                            }, null, 8, _hoisted_10)
                          ])
                        ]))
                      : (_openBlock(), _createElementBlock("img", {
                          key: 1,
                          src: `/public/img/slides/${slide.img}`,
                          alt: "slider-image"
                        }, null, 8, _hoisted_11)),
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("h4", _hoisted_13, _toDisplayString(slide.headline), 1),
                      _createElementVNode("p", _hoisted_14, _toDisplayString(slide.body), 1),
                      ((index + 1) < onboardingSlides.value.length)
                        ? (_openBlock(), _createElementBlock("hr", _hoisted_15))
                        : _createCommentVNode("", true)
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ])
      ])
    ], 512), [
      [_vShow, _unref(isOpen)]
    ])
  ]))
}
}

}