import { unref as _unref, createElementVNode as _createElementVNode, withKeys as _withKeys, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["aria-label"]
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 0,
  class: "menu"
}

import { useRouter } from 'vue-router';
    import { computed, onMounted, ref } from 'vue';
    import { useStore } from 'vuex';

    import { useI18next } from '@composables/i18next';

    import HowToPlayModal from '@public/js/modals/HowToPlayModal.vue';
    import { resolveWebpackI18nAsset } from '../plugins/I18nAssetsPlugin';

    
export default {
  __name: 'TheHeader',
  setup(__props) {

    const { t, vT } = useI18next(['footer', 'links', 'global']);

    const howToPlayModal = ref(null);
    const router = useRouter();
    const store = useStore();

    const h1 = ref();

    const backgroundClass = computed(() =>
        (['gameBoard'].includes(store.state.ui.pageName) ? 'has-background-white' : ''));

    const showNav = computed(() => (['gameBoard'].includes(store.state.ui.pageName)));

    onMounted(() => {
        // Focus the h1 element to force screen readers back to the top after a navigation.
        // This will ensure screen readers will read the page again.
        // https://a11y-guidelines.orange.com/en/articles/single-page-app/#notify-user-of-page-changes
        router.afterEach(() => {
            if (h1.value) {
                h1.value.focus();
            }
        });
    });

    // Methods
    function openModel ()  {
        howToPlayModal.value.openModal();
    }

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("header", {
    class: _normalizeClass(["the-header mb-xl", `${backgroundClass.value}`]),
    "data-e2e": "global-header"
  }, [
    _createElementVNode("h1", {
      ref_key: "h1",
      ref: h1,
      tabindex: "-1",
      "aria-label": _unref(t)('global.how_to_play')
    }, [
      _createElementVNode("img", {
        src: _unref(resolveWebpackI18nAsset)('img/starbucks-logo.svg'),
        alt: ""
      }, null, 8, _hoisted_2)
    ], 8, _hoisted_1),
    (showNav.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _withDirectives(_createElementVNode("a", {
            class: "menu-list has-cursor-pointer is-upppercase",
            tabindex: "0",
            onKeyup: _withKeys(openModel, ["enter"]),
            onClick: openModel
          }, null, 544), [
            [_unref(vT), 'global:how_to_play']
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(HowToPlayModal, {
      ref_key: "howToPlayModal",
      ref: howToPlayModal
    }, null, 512)
  ], 2))
}
}

}