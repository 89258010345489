import { createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-error page-lifecycle" }
const _hoisted_2 = { class: "card" }
const _hoisted_3 = { class: "card-header mt-s" }
const _hoisted_4 = ["src", "alt"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_t = _resolveDirective("t")

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          src: _ctx.$i18nAsset('img/bingo-header.png'),
          alt: $setup.t('global:title')
        }, null, 8, _hoisted_4)
      ]),
      _withDirectives(_createElementVNode("h2", null, null, 512), [
        [_directive_t, 'error:headline_copy']
      ]),
      _withDirectives(_createElementVNode("p", null, null, 512), [
        [_directive_t, 'error:body_copy']
      ])
    ])
  ]))
}