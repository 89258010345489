
// This page component is not dynamically imported, which is one
// way of ensuring it's not split out into a separate async chunk.
import ErrorPage from '../views/ErrorPage.vue';

export default [
    { path: '/error/:error?', component: ErrorPage, meta: {
        public: true,
        activeInPhase: ':any',
        activeInMaint: true,
    }, props: true },
];
