import { unref as _unref, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@public/img/icon-circle-check.svg'


const _hoisted_1 = { class: "language-selector" }
const _hoisted_2 = ["href"]
const _hoisted_3 = ["href"]

import { useStore } from 'vuex';
    import { useI18next } from '@composables/i18next';

    
export default {
  __name: 'LanguageSelector',
  setup(__props) {

    const store = useStore();
    const { vT } = useI18next('global', { keyPrefix: 'languageSelector' });


    function getUrlForLocale (locale) {
        let url = store.state.app.siteURL;
        const suffixUrl = window.location.hash;

        const vanityDomains = ['starbucksstardays', 'joursetoilesstarbucks'];

        if (vanityDomains.some(domain => url.includes(domain))) {
            return locale.includes('fr') ?
                'https://www.joursetoilesstarbucks.ca' :
                'https://www.starbucksstardays.ca';
        }

        if (url.includes('starbucks')) {
            const baseDomain = 'starbucks-stardaysbingo2024';
            const subDomainRegex = /(?:http[s]*:\/\/)*(.*?)\.(?=[^/]*\..{2,5})/i;
            const subDomain = url.match(subDomainRegex)[1];
            const suffix = locale.includes('fr') ? '-frca' : '-ca';
            url = url.replace(subDomain, `${baseDomain}${suffix}`);
            url = [url, suffixUrl].join('/');
        }
        else {
            url = `${url}?locale=${locale}${suffixUrl}`;
        }

        return url;
    }

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    _createElementVNode("li", null, [
      (_unref(store).state.app.userLanguage === 'en')
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _cache[0] || (_cache[0] = _createElementVNode("img", {
              src: _imports_0,
              role: "presentation",
              alt: ""
            }, null, -1)),
            _withDirectives(_createElementVNode("span", null, null, 512), [
              [_unref(vT), 'en']
            ])
          ], 64))
        : (_openBlock(), _createElementBlock("a", {
            key: 1,
            href: getUrlForLocale('en-CA')
          }, [
            _withDirectives(_createElementVNode("span", null, null, 512), [
              [_unref(vT), 'en']
            ])
          ], 8, _hoisted_2))
    ]),
    _createElementVNode("li", null, [
      (_unref(store).state.app.userLanguage === 'fr')
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _cache[1] || (_cache[1] = _createElementVNode("img", {
              src: _imports_0,
              role: "presentation",
              alt: ""
            }, null, -1)),
            _withDirectives(_createElementVNode("span", null, null, 512), [
              [_unref(vT), 'fr']
            ])
          ], 64))
        : (_openBlock(), _createElementBlock("a", {
            key: 1,
            href: getUrlForLocale('fr-CA')
          }, [
            _withDirectives(_createElementVNode("span", null, null, 512), [
              [_unref(vT), 'fr']
            ])
          ], 8, _hoisted_3))
    ])
  ]))
}
}

}