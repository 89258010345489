import { createElementVNode as _createElementVNode, unref as _unref, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@public/img/aeroplan.png'


const _hoisted_1 = { class: "aeroplan-container" }
const _hoisted_2 = { class: "mt-s" }
const _hoisted_3 = { class: "mt-s mb-s" }
const _hoisted_4 = ["href"]

import { useI18next } from '@composables/i18next';


    // Setup
    
export default {
  __name: 'AeroplanContainer',
  setup(__props) {

    const { t, vT } = useI18next('aeroplan');


return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("img", {
      src: _imports_0,
      alt: "Aeroplan"
    }, null, -1)),
    _withDirectives(_createElementVNode("p", _hoisted_2, null, 512), [
      [_unref(vT), 'body_copy']
    ]),
    _withDirectives(_createElementVNode("p", _hoisted_3, null, 512), [
      [_unref(vT), 'body_copy_2']
    ]),
    _withDirectives(_createElementVNode("a", {
      class: "button has-background-moderate-gold mb-xs",
      target: "_blank",
      href: _unref(t)('cta_url')
    }, null, 8, _hoisted_4), [
      [_unref(vT), 'cta']
    ])
  ]))
}
}

}